import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "White Paper Writing Services",
  whatWeOffer: {
    leftPart: "Top brands in almost every industry use white papers to inform their audiences about a complex topic. They are an excellent way to explain your brand's philosophy, provide guidance, and help turn leads into customers.",
    rightPart: "Our White Paper Writing Service focuses on providing your customers with a steady flow of top-quality white papers. Whether you want to explain the excellent features of your new product, or you want to address a serious issue, you can count on us."
  },
  processText: "rrr",
  testimonials: testimonials
}

const meta = {
  title: "White Paper Writing Services",
  description: "Top brands in almost every industry use white papers to inform their audiences about a complex topic. They are an excellent way to explain your brand's philosophy, provide guidance, and help turn leads into customers."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/white-paper-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)